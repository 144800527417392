var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      attrs: { value: _vm.currBiz, filterable: "" },
      on: { change: _vm.changeCurrBiz },
    },
    _vm._l(_vm.bizList, function (biz) {
      return _c("el-option", {
        key: biz.id,
        attrs: { label: biz.name + "(" + biz.code + ")", value: biz.id },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }